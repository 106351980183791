import { useEffect } from "react";
import React from 'react';
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate
} from "react-router-dom";
import { allRoutes } from "./routes";
import error from "./pages/page404";

// Extend window object to include gtag and dataLayer
declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: any[]; // Declare dataLayer as an array
  }
}

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const getComponent = (component: React.ComponentType<any>) => {
    return React.createElement(component, {});
  };

  // Scroll to top on navigation change
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  // Dynamic title and meta description
  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home";
        metaDescription = "This is the home page.";
        break;
      // Add cases for other routes if necessary
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  // Google Analytics Integration
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (window.gtag) {
        window.gtag('config', 'G-9Q0SWLXJPL', {
          page_path: url,
        });
      }
    };

    // Trigger page view when pathname changes
    handleRouteChange(pathname);
  }, [pathname]);

  // Load the Google Analytics script
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-9Q0SWLXJPL`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', 'G-9Q0SWLXJPL');
    };
  }, []);

  return (
    <Routes>
      {/* English routes and default routes */}
      <Route path={"/*"} element={getComponent(error)} />
      <Route path={"/"} element={<Navigate replace to={"/en/home"} />} />
      <Route path={"/en"} element={<Navigate replace to={"/en/home"} />} />
      {allRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<Navigate replace to={"/en" + route.path} />}
        />
      ))}
      {allRoutes.map((route, index) => (
        <Route
          key={index}
          path={"/en" + route.path}
          element={getComponent(route.component)}
        />
      ))}
      {allRoutes.map((route, index) => (
        <Route
          key={index}
          path={"/en" + route.path + "/*"}
          element={getComponent(route.component)}
        />
      ))}
      {/* Hindi routes */}
      <Route path={"/hi"} element={<Navigate replace to={"/hi/home"} />} />
      {allRoutes.map((route, index) => (
        <Route
          key={index}
          path={"/hi" + route.path}
          element={getComponent(route.component)}
        />
      ))}
      {allRoutes.map((route, index) => (
        <Route
          key={index}
          path={"/hi" + route.path + "/*"}
          element={getComponent(route.component)}
        />
      ))}
      {/* Spanish routes */}
      <Route path={"/es"} element={<Navigate replace to={"/es/home"} />} />
      {allRoutes.map((route, index) => (
        <Route
          key={index}
          path={"/es" + route.path}
          element={getComponent(route.component)}
        />
      ))}
      {allRoutes.map((route, index) => (
        <Route
          key={index}
          path={"/es" + route.path + "/*"}
          element={getComponent(route.component)}
        />
      ))}
    </Routes>
  );
}

export default App;
