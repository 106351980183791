import { FunctionComponent } from "react";

import { Seo } from "../utilities/Seo";
const terms: FunctionComponent = () => {
  return (
    <>
      <Seo />
      <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-buttons-button-lg">
        <div
          className="container mt-[50px] mb-[auto] my-6 mx-auto p-[20px] w-[90%]"
          style={{
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            maxWidth: "800px",
            margin: "auto",
          }}
        >
          <div className="grid grid-cols-1 gap-4">
            <div className="w1-[132px] mq360:w-[auto] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
              <img
                className="h-10 relative max-w-full overflow-visible shrink-0 object-cover mq360:ms-[8px] mq550:max-w-[116px]"
                alt=""
                src="/logo@2x.png"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 mt-[50px] mb-[50px] text-justify">
            <h1 className="text-center">Terms of Use</h1>
           
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>Date:</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <br />
      Welcome to ResAI, the innovative AI-powered platform owned and operated by
      <b>ResAI Technologies Private Limited</b>. These
      <b>Terms of Use</b> (referred to as the &quot;Terms&quot;) constitute a
      legally binding agreement between you (referred to as &quot;you,&quot;
      &quot;your,&quot; or the &quot;User&quot;) and
      <b>ResAI Technologies Private Limited</b> (&quot;ResAI,&quot;
      &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). These Terms govern
      your access and use of our website, mobile applications, and all services
      (collectively, the &quot;Services&quot;) provided by ResAI.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      By accessing or using our Services, you agree to comply with and be bound
      by these Terms, our <b>Privacy Policy</b>, and any additional terms
      applicable to specific Services. If you do not agree with any part of
      these Terms, you must not access or use our Services.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>1. Acceptance of Terms</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      By creating an account, accessing the Services, or using any part of
      ResAI's platform, you acknowledge that you have read, understood, and
      agree to be bound by these Terms. You represent that you are legally
      capable of entering into binding contracts under applicable laws. If you
      are using the Services on behalf of an organization, you affirm that you
      have the authority to bind that organization to these Terms.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>1.1. Changes to the Terms</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI reserves the right to modify or revise these Terms at any time at
      its sole discretion. Any changes to the Terms will be posted on the
      platform, and you will be notified through email or a prominent notice on
      the platform. Your continued use of the Services after any modifications
      to the Terms constitutes acceptance of those changes. You are responsible
      for regularly reviewing these Terms to stay informed of updates.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>2. Description of Services</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI provides a suite of AI-driven tools designed to assist users in
      building comprehensive resumes, cover letters, and portfolios. Our
      Services are centered around delivering AI-enhanced documents that cater
      to the unique skills and qualifications of each user. The key features of
      our Services include:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>AI-Powered Resume Creation</b>: Our system leverages artificial
          intelligence to create resumes tailored to the user's input, skill
          set, and job description.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Cover Letters and Portfolios</b>: Users can generate customized
          cover letters and professional portfolios to complement their resumes,
          further enhancing their job applications.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Effortless Data Collection</b>: Our AI-driven user interface (UX)
          simplifies the process of inputting personal and professional data,
          making it seamless for users to provide the necessary information.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Skill Extraction Algorithm</b>: The platform employs advanced
          algorithms to identify and highlight key skills from the information
          provided by users. This ensures that the most relevant qualifications
          are presented in the resume.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Multilingual Support</b>: ResAI allows users to input information
          in their native language, removing language barriers and improving
          user experience. Our platform then translates the information to
          present it in a professional format.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Job Recommendations</b>: ResAI features access to a vast database
          of current job openings worldwide. Users receive job recommendations
          tailored to their qualifications and the positions they are most
          likely to be a match for.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Skill-Gap Analysis and Course Recommendations</b>: ResAI analyzes
          the user's resume and suggests relevant courses or certifications that
          can bridge any identified skill gaps, empowering users to improve
          their employability.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      Our platform is designed to act as a one-stop solution for users looking
      to present their skills comprehensively, apply for jobs with tailored
      resumes, and receive guidance toward professional success.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3. User Eligibility and Registration</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      To use ResAI’s Services, you must meet the following conditions:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Age Requirement</b>: There is no age restrictions.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Legally Binding Agreement</b>: You must have the legal capacity to
          enter into a binding contract with ResAI, as defined under applicable
          laws.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Compliance with Laws</b>: You agree to use the Services in
          compliance with all applicable laws and regulations, including but not
          limited to employment and data privacy laws.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3.1. Account Registration</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      To access our Services, you must create an account by providing accurate
      and up-to-date information. During the registration process, you will be
      required to provide personal details, including but not limited to your
      name, email address, and password. By registering for an account, you
      agree to:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Provide accurate, current, and complete information.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Promptly update any information that may become outdated or
          inaccurate.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Be solely responsible for maintaining the confidentiality of your
          login credentials.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Be responsible for all activities under your account, including
          unauthorized access or use.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      You must notify us immediately if you suspect any unauthorized use of your
      account or any other breach of security.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>3.2. Account Termination</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI reserves the right to terminate or suspend your account at any time,
      with or without notice, for any reason, including but not limited to
      violations of these Terms, illegal activities, or misuse of the Services.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>4. Subscription Plans, Payments, and Refunds</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI offers both free and paid subscription plans. Free plans provide
      users with access to basic features, while premium plans unlock additional
      services and capabilities. The terms of subscription, pricing, and billing
      are detailed below:
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>4.1. Subscription Tiers</b>
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Free Plan</b>: Users can create a limited number of resumes and
          cover letters with access to basic features. Some advanced features
          may be restricted to premium subscribers.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Premium Plans</b>: Premium subscribers have access to advanced
          features, such as AI-enhanced portfolio creation, customized cover
          letters, skill-gap analysis, and tailored job recommendations. Premium
          plans may be offered on a monthly, quarterly, or annual basis, with
          corresponding fees.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>4.2. Payment Terms</b>
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Payment Methods</b>: Payments for premium subscription plans can be
          made using valid credit cards, debit cards, or other payment methods
          supported by ResAI. All payments must be made in full and in advance
          for the selected subscription period.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Recurring Billing</b>: If you choose a subscription plan with
          recurring billing, you authorize ResAI to automatically charge your
          payment method on a periodic basis (monthly, quarterly, or annually)
          until you cancel your subscription.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Price Changes</b>: ResAI reserves the right to change the pricing
          of its subscription plans at any time. However, price changes will
          only apply to future subscription renewals and will not affect the
          current subscription period. You will be notified of any price changes
          before they take effect.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>4.3. Refunds</b>
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Refund Policy</b>: Payments for subscription plans are generally
          non-refundable, except as required by law. If you experience issues
          with your subscription, please contact ResAI's customer support for
          assistance.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Cancellations</b>: You may cancel your subscription at any time
          through your account settings. Upon cancellation, your access to
          premium features will continue until the end of your current
          subscription period, and no further payments will be charged. No
          partial refunds will be issued for unused portions of the
          subscription.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>5. Use of AI and Data Collection</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI leverages advanced artificial intelligence technologies to analyze
      user-provided information and generate customized resumes, cover letters,
      portfolios, and job recommendations. By using the Services, you consent to
      ResAI’s collection and analysis of your personal and professional data.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>5.1. Types of Data Collected</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI collects the following types of data to provide its Services:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Personal Information</b>: Information such as your name, contact
          details, and professional background (e.g., education, work
          experience, certifications).
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Resume and Job-Related Information</b>: Data related to your
          employment history, job descriptions, skills, and qualifications.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Uploaded Documents</b>: Documents you upload to the platform,
          including resumes, cover letters, certificates, and portfolios.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>5.2. Use of Data</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI uses the data you provide to:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Generate AI-enhanced resumes, cover letters, and portfolios.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Match your qualifications to relevant job opportunities.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Provide personalized skill-gap analysis and course recommendations.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Improve the quality and accuracy of our AI algorithms.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI may also aggregate anonymized data for research, analysis, and
      product improvement purposes. Personal data will not be shared with third
      parties without your explicit consent, except as necessary to provide the
      Services or comply with legal requirements.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>6. AI-Powered Skill Extraction and Recommendations</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      One of the core features of ResAI is its ability to analyze user input and
      extract relevant skills and qualifications. The platform’s skill
      extraction algorithm identifies key competencies based on the user’s
      provided data and job descriptions. However, it is important to note that:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>AI Limitations</b>: While our AI-driven algorithms aim for high
          accuracy, there may be instances where the system misidentifies or
          overlooks certain skills. Users should review all output documents to
          ensure that the information accurately reflects their qualifications.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Course Recommendations</b>: Based on identified skill gaps, ResAI
          suggests online courses and certifications to help users bridge those
          gaps. These recommendations are generated by analyzing both the user’s
          resume and the job descriptions for which they are applying. Users are
          encouraged to independently assess the value and relevance of any
          recommended courses.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI does not guarantee any specific outcomes from following the course
      recommendations, as career success depends on various factors, including
      market conditions and the user’s personal initiative.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>7. Multilingual Support and Language Features</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI’s platform is designed to be inclusive by allowing users to input
      information in their native language. This feature enables users to create
      resumes and other professional documents without facing language barriers.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>7.1. Language Processing and Translation</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      The platform automatically processes the data inputted in different
      languages and translates it into a professional resume format. However,
      translation is not always perfect. While ResAI strives to provide accurate
      translations, you should review the translated content for correctness
      before using it professionally.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>7.2. User Responsibility for Accuracy</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      As with all AI-generated content, users are responsible for verifying the
      accuracy and appropriateness of the final output. ResAI is not liable for
      any errors, misinterpretations, or inaccuracies arising from language
      translation.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>8. Job Database and Recommendations</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI offers users access to a comprehensive database of job listings from
      around the world. Based on the user's profile, resume, and identified
      skills, the platform provides job recommendations tailored to the user's
      qualifications and career goals.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>8.1. Job Data Sources</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI collects job listings from various publicly available and
      third-party sources. While we strive to provide the most up-to-date and
      accurate job openings, ResAI does not guarantee the accuracy or
      completeness of the job listings displayed on the platform. Users are
      responsible for verifying job details, such as job descriptions,
      application deadlines, and qualifications, directly with the employers or
      job boards.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>8.2. Job Matching and Recommendations</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI’s algorithm matches users with job opportunities based on the
      information provided in their resumes and career preferences. However,
      ResAI does not guarantee that users will receive job offers or interviews
      based on these recommendations. Users should exercise their discretion and
      judgment when applying for jobs and conducting job searches.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>8.3. Job Application Support</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI may provide tools to help users apply for jobs directly through the
      platform. However, the final application process may require users to
      submit additional information or complete applications through external
      employer websites. ResAI is not responsible for the application process,
      nor does it guarantee that applications submitted through the platform
      will be received or reviewed by employers.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>9. User Responsibilities and Conduct</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      As a user of ResAI, you agree to adhere to the following responsibilities
      and standards of conduct:
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>9.1. Prohibited Conduct</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      You must not:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Use the Services for any illegal or unauthorized purposes.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Falsify or misrepresent your identity, qualifications, or professional
          background.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Provide false or misleading information in your resume, cover letter,
          or other documents.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Use the Services to distribute spam, unsolicited messages, or
          advertising.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Access, tamper with, or use non-public areas of the platform, ResAI’s
          computer systems, or its technical delivery systems.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Interfere with, disrupt, or attempt to disrupt the proper functioning
          of the Services or the servers and networks connected to the platform.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>9.2. User-Generated Content</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      You are responsible for any content you submit or upload to the platform,
      including but not limited to resumes, cover letters, job descriptions, and
      other professional documents. You warrant that:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          You have the right to use and upload the content.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          The content does not infringe on any third-party rights, including
          intellectual property, privacy, or publicity rights.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          The content is not false, misleading, defamatory, obscene, or
          unlawful.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI reserves the right to remove any content that violates these Terms
      or is otherwise deemed inappropriate.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>10. Intellectual Property Rights</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI Technologies Private Limited owns and retains all rights, titles,
      and interests in and to the Services, including but not limited to:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          All proprietary software, algorithms, and AI models.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          The design, layout, graphics, and functionality of the platform.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Trademarks, logos, and branding associated with ResAI.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      By using the Services, you do not acquire any ownership rights to the
      platform, the software, or any proprietary materials owned by ResAI
      Technologies Private Limited. You are granted a limited, non-exclusive,
      non-transferable, and revocable license to access and use the Services
      solely for personal, non-commercial purposes, as permitted by these Terms.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>10.1. License to Use User Content</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      By submitting or uploading content to the platform, you grant ResAI a
      worldwide, non-exclusive, royalty-free, fully-paid, and transferable
      license to use, reproduce, distribute, and display your content in
      connection with providing the Services. This license includes the right to
      modify and adapt your content for the purpose of enhancing resumes, cover
      letters, and job recommendations.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      You retain ownership of any content you submit, and nothing in these Terms
      limits your right to use your content outside of the platform.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>11. Privacy and Data Security</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI is committed to protecting your privacy and personal data. Our
      <b>Privacy Policy</b> explains how we collect, use, and protect your
      information when you use our Services. By using the platform, you consent
      to the collection and processing of your personal data in accordance with
      the Privacy Policy.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>11.1. Data Security Measures</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI implements industry-standard security measures to protect your data
      from unauthorized access, loss, or misuse. However, no method of
      transmission over the internet or electronic storage is 100% secure. While
      we strive to protect your personal data, we cannot guarantee its absolute
      security. You acknowledge and accept the inherent risks of providing
      personal information online.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>11.2. Data Retention</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI retains user data for as long as necessary to provide the Services
      or as required by applicable laws. If you close your account, we may
      retain certain information for a limited period to comply with legal
      obligations or resolve disputes.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>12. Third-Party Services and Links</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI may contain links to third-party websites, services, or resources
      that are not controlled by us. These links are provided for convenience
      and informational purposes only. ResAI is not responsible for the content,
      accuracy, availability, or privacy practices of any third-party websites
      or services.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>12.1. Interaction with Third-Party Services</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      If you choose to interact with third-party services linked to ResAI, such
      interactions are solely between you and the third party. Your use of
      third-party services is governed by the terms and conditions of those
      services, and you should review them carefully before proceeding.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI disclaims any responsibility for damages or losses incurred through
      interactions with third-party services.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>13. Termination of Services</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI reserves the right to terminate or suspend your account and access
      to the Services, with or without notice, at our sole discretion. Reasons
      for termination may include:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Violation of these Terms or any applicable laws.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Conduct that harms or disrupts the platform or other users.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Non-payment of subscription fees or failure to comply with payment
          terms.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>13.1. Effect of Termination</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      Upon termination, your right to access and use the Services will
      immediately cease. ResAI may, at its discretion, retain or delete your
      account data, including any resumes, cover letters, or other documents, in
      accordance with our data retention policies.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      You will not be entitled to any refund of subscription fees for the
      remaining portion of the subscription period after termination.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>14. Limitation of Liability</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      To the maximum extent permitted by law, ResAI Technologies Private Limited
      and its affiliates, officers, directors, employees, and agents shall not
      be liable for any indirect, incidental, special, consequential, or
      punitive damages, including but not limited to:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Loss of employment opportunities or professional advancement.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Inaccuracies or errors in resumes, cover letters, or job
          recommendations.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Unauthorized access to your account or personal data.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Any other damages arising out of or in connection with your use of the
          Services.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      In no event shall ResAI’s aggregate liability exceed the total amount you
      have paid for the Services in the six months preceding the event giving
      rise to the claim.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>14.1. Disclaimer of Warranties</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI provides the Services &quot;as is&quot; and &quot;as
      available,&quot; without any warranties of any kind, either express or
      implied. To the fullest extent permitted by law, ResAI disclaims all
      warranties, including but not limited to:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Warranties of merchantability, fitness for a particular purpose, or
          non-infringement.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Warranties regarding the accuracy, reliability, or completeness of the
          Services.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Warranties that the Services will meet your expectations, be
          error-free, or be uninterrupted.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      You acknowledge that the use of the Services is at your own risk.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>15. Indemnification</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      You agree to indemnify, defend, and hold harmless ResAI Technologies
      Private Limited and its affiliates, officers, directors, employees, and
      agents from and against any claims, damages, liabilities, losses, or
      expenses arising out of or in connection with:
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Your use of the Services.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Any content you upload, submit, or otherwise make available through
          the platform.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Your violation of these Terms or any applicable laws.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          Your infringement of any third-party rights, including intellectual
          property or privacy rights.
        </p>
      </li>
    </ul>

    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI reserves the right to assume the exclusive defense and control of
      any matter subject to indemnification, in which case you agree to
      cooperate fully with our defense.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>16. Modifications to the Terms</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      ResAI reserves the right to modify, update, or change these Terms at any
      time. Any modifications will be effective immediately upon posting the
      revised Terms on the platform. ResAI will provide notice of any material
      changes through the platform or via email.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>16.1. User Acknowledgment</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      Your continued use of the Services after the posting of revised Terms
      constitutes your acceptance of the modifications. If you do not agree with
      the revised Terms, you must discontinue your use of the Services and close
      your account.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>17. Governing Law and Dispute Resolution</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      These Terms, and any disputes arising out of or related to them, shall be
      governed by and construed in accordance with the laws of [Insert
      Jurisdiction], without regard to its conflict of law principles.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>17.1. Dispute Resolution</b>
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      Any disputes arising out of or related to these Terms or your use of the
      Services shall be resolved through binding arbitration in accordance with
      the rules of the [Insert Arbitration Body]. The arbitration shall take
      place in [Insert Location], and the language of the arbitration shall be
      English. The arbitration award shall be final and binding, and judgment
      may be entered in any court of competent jurisdiction.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      You agree that any claim or cause of action arising out of or related to
      these Terms must be filed within one (1) year after the claim or cause of
      action arose, or it shall be forever barred.
    </p>
    <p  className="line-height: 108%; margin-bottom: 0.28cm">
      <b>18. Miscellaneous</b>
    </p>
    <ul>
      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Entire Agreement</b>: These Terms, along with the Privacy Policy
          and any additional terms related to specific Services, constitute the
          entire agreement between you and ResAI Technologies Private Limited
          regarding the use of the platform.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Severability</b>: If any provision of these Terms is found to be
          invalid or unenforceable, the remaining provisions shall remain in
          full force and effect.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Waiver</b>: No waiver of any provision of these Terms shall be
          deemed a further or continuing waiver of such provision or any other
          provision, and ResAI’s failure to assert any right or provision under
          these Terms shall not constitute a waiver of such right or provision.
        </p>
      </li>

      <li>
        <p  className="line-height: 108%; margin-bottom: 0.28cm">
          <b>Assignment</b>: You may not assign or transfer any rights or
          obligations under these Terms without prior written consent from ResAI
          Technologies Private Limited. ResAI may assign or transfer its rights
          and obligations under these Terms at its discretion.
        </p>
      </li>
    </ul>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default terms;
