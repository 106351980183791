import { FunctionComponent } from "react";

export type FrameComponent4Type = {
  className?: string;
};

const FrameComponent4: FunctionComponent<FrameComponent4Type> = ({
  className = "",
}) => {
  return (
    <div
      className={`mq1050:invisible mq1050:h-[0px]  h-[auto] self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-right text-xs text-darkslategray font-caption ${className}`}
    >
      <div className="h-[751px] w-[1002px] relative shrink-0 max-w-full mq1050:h-auto mq1050:min-h-[751]">
        <div className="absolute top-[0px] left-[0px] rounded-t-xl rounded-b-none bg-gray-400 w-[1002px] h-[721px] z-[2]" />
        <div className="absolute top-[0px] left-[0px] rounded-t-xl rounded-b-none bg-neutral-white box-border w-full flex flex-row items-start justify-between pt-[5px] pb-1.5 pr-[29px] pl-4 max-w-full gap-[20px] z-[3] border-t-[1px] border-solid border-neutrals-n10 border-r-[1px] border-l-[1px] mq1050:flex-wrap">
          <div className="h-10 w-[1002px] relative rounded-t-xl rounded-b-none bg-neutral-white box-border hidden max-w-full border-t-[1px] border-solid border-neutrals-n10 border-r-[1px] border-l-[1px]" />
          <div className="flex flex-row items-start justify-start gap-[48px] max-w-full mq750:flex-wrap mq750:gap-[24px]">
            <div className="w-[52px] flex flex-col items-start justify-start pt-[7px] px-0 pb-0 box-border">
              <div className="self-stretch h-3 relative">
                <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-alerts-warning w-3 h-3 z-[1]" />
                <div className="absolute top-[0px] left-[20px] rounded-[50%] bg-mediumaquamarine w-3 h-3 z-[1]" />
                <div className="absolute top-[0px] left-[40px] rounded-[50%] bg-alerts-error w-3 h-3 z-[1]" />
              </div>
            </div>
            <div className="w-[132px] flex flex-col items-start justify-start pt-px px-0 pb-0 box-border">
              <div className="w-[88px] flex flex-row items-start justify-start gap-[21px]">
                <div className="flex flex-col items-start justify-start pt-1 px-0 pb-0">
                  <img
                    className="w-4 h-4 relative z-[1]"
                    alt=""
                    src="/group-8373.svg"
                  />
                </div>
                <div className="h-6 flex-1 relative">
                  <img
                    className="absolute top-[0px] left-[0px] w-6 h-6 object-contain z-[1]"
                    alt=""
                    src="/group-8251@2x.png"
                  />
                  <img
                    className="absolute top-[0px] left-[27px] w-6 h-6 z-[1]"
                    alt=""
                    src="/group-8366.svg"
                  />
                </div>
              </div>
            </div>
            <div className="w-[411px] bg-aera-background-grey flex flex-row items-start justify-between pt-[5px] pb-1.5 pr-28 pl-[113px] box-border max-w-full gap-[20px] z-[1] mq450:pl-5 mq450:pr-5 mq450:box-border">
              <div className="h-[27px] w-[411px] relative bg-aera-background-grey hidden max-w-full" />
              <div className="flex flex-col items-start justify-start pt-0.5 px-0 pb-0">
                <img
                  className="w-3 h-3 relative z-[1]"
                  alt=""
                  src="/group-8367.svg"
                />
              </div>
              <div className="relative inline-block min-w-[99px] z-[1]">
                https://ResAI.com
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start pt-[5px] px-0 pb-0">
            <div className="flex flex-row items-start justify-start gap-[20px]">
              <img
                className="h-4 w-4 relative min-h-[16px] z-[1]"
                alt=""
                src="/group-8372.svg"
              />
              <img
                className="h-4 w-4 relative min-h-[16px] z-[1]"
                alt=""
                src="/group-8371.svg"
              />
              <img
                className="h-4 w-4 relative min-h-[16px] z-[1]"
                alt=""
                src="/group-8369.svg"
              />
            </div>
          </div>
        </div>
        <div className="absolute top-[39px] left-[0px] w-full flex flex-row items-start justify-end py-[250px] px-[22px] box-border bg-[url('/public/frame-2@3x.png')] bg-cover bg-no-repeat bg-[top] min-h-[712px] max-w-full z-[4] text-left text-lg text-primary-16x-p150 font-buttons-button-lg">
          <img
            className="h-[712px] w-[1002px] relative object-cover hidden max-w-full"
            alt=""
          />
          <div className="w-[408px] shadow-[0px_19px_38px_rgba(0,_0,_0,_0.1)] rounded-xl bg-neutral-white box-border flex flex-col items-start justify-start py-[22px] px-[23px] max-w-full z-[6] border-[1px] border-solid border-neutrals-n20">
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[16px]">
              <img
                className="h-8 w-8 relative overflow-hidden shrink-0"
                alt=""
                src="/resume-preview-header.svg"
              />
              <div className="flex-1 flex flex-row items-center justify-start min-w-[203px]">
                <div className="flex-1 flex flex-row items-center justify-center">
                  <div className="flex-1 flex flex-col items-start justify-center">
                    <div className="self-stretch flex flex-col items-start justify-center gap-[16px]">
                      <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
                        <div className="self-stretch flex flex-row items-center justify-between gap-[20px]">
                          <div className="relative leading-[18px] font-semibold inline-block min-w-[81px]">
                            Google LLC
                          </div>
                          <div className="h-[9px] w-1" />
                        </div>
                        <div className="flex flex-row items-start justify-start gap-[12px] text-sm text-neutrals-n50">
                          <div className="relative leading-[14px] inline-block min-w-[112px]">{`Full Stack Developer `}</div>
                          <div className="relative text-base tracking-[-0.02em] leading-[16px] font-medium font-outfit inline-block min-w-[8px]">
                            -
                          </div>
                          <div className="relative leading-[14px] inline-block min-w-[96px]">
                            Chicago (Onsite)
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-end gap-[8px] text-center text-base text-primary-16x-p60">
                        <div className="relative tracking-[-0.02em] leading-[16px] font-semibold inline-block min-w-[52px]">
                          View job
                        </div>
                        <div className="flex flex-row items-center justify-start">
                          <img
                            className="h-[9px] w-1 relative"
                            alt=""
                            src="/vector-388.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent4;
